import { createTheme, Paper, ThemeProvider, Typography } from "@mui/material";
import { action, createStore, StoreProvider } from "easy-peasy";
import React, { useEffect } from "react";
import "../styles/Layout.scss";
import "../styles/PrizesLeftBlock.scss";
import Header from "./Header";
import { StoreModel } from "../store/storeModel";
import { createTypedHooks } from "easy-peasy";
import { Helmet } from "react-helmet";
import { useStoreState } from "./Layout";
import { getLeader } from "../utils/teamsHelper";

function PrizesLeftBlock() {
  const responses = useStoreState((state) => state.responses);
  const fixtures = useStoreState((state) => state.fixtures);
  const results = useStoreState((state) => state.results);
  return (
    <div className="prizesleft-container">
      <div className="prizesleft-container__triangle"></div>
      <Typography
        variant="body2"
        textAlign={"center"}
        style={{ marginTop: "0rem", marginBottom: "0rem", lineHeight: "1" }}
        gutterBottom
        fontSize={"1.4rem"}
        component="div"
      >
        Vinnare
      </Typography>
      <Typography
        variant="body2"
        textAlign={"center"}
        style={{ marginTop: "0rem", marginBottom: "0rem" }}
        gutterBottom
        component="div"
        fontSize={"2.4rem"}
      >
        {getLeader(responses, fixtures, results[0])?.name}
      </Typography>
    </div>
  );
}

export default PrizesLeftBlock;
