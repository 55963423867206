import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Button, CircularProgress, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useStoreState } from "../components/Layout";
import { Match, Responses } from "../store/storeModel";
import ViewRow from "../components/ViewRow";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { getFamilyPoints, getTotalGoals, getTotalGoalsOfResponse, getTotalPoints } from "../utils/teamsHelper";
import { Helmet } from "react-helmet";

function Standings() {
  const fixtures = useStoreState((state) => state.fixtures);
  const responses = useStoreState((state) => state.responses);
  const results = useStoreState((state) => state.results);

  const baseUrl =
    process.env.NEXT_STATIC_BASE_URL || "https://tipset-server.onrender.com";

  useEffect(() => {}, []);

  const text = "#cfcece";
  const navigate = useNavigate();
  return (
    <>
      <Helmet>
        <title>Tabell</title>
      </Helmet>
      <Typography
        variant="h2"
        textAlign={"center"}
        fontSize={24}
        fontWeight={600}
        style={{ marginTop: "1rem" }}
        gutterBottom
        component="div"
        color={text}
      >
        Individuell
      </Typography>
      <TableContainer className="standings-table-container" component={Paper}>
        <Table sx={{ minWidth: 150 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Deltagare</TableCell>
              <TableCell align="right">3p</TableCell>
              <TableCell align="right">2p</TableCell>
              <TableCell align="right">0p</TableCell>
              <TableCell align="right">Poäng</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {responses
              .sort(
                (x, y) =>
                  getTotalPoints(responses, fixtures, results[0], y.id).points -
                  getTotalPoints(responses, fixtures, results[0], x.id).points || getTotalPoints(responses, fixtures, results[0], y.id).threep -
                  getTotalPoints(responses, fixtures, results[0], x.id).threep || getTotalPoints(responses, fixtures, results[0], y.id).threepgoals -
                  getTotalPoints(responses, fixtures, results[0], x.id).threepgoals || Math.abs(getTotalGoals(fixtures) - getTotalGoalsOfResponse(responses, x.id)) - Math.abs(getTotalGoals(fixtures) - getTotalGoalsOfResponse(responses, y.id))
              )
              .map((row, index) => (
                <TableRow
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    <>
                      {index === 0 && (
                        <img className="crownIcon" src="crown.png"></img>
                      )}
                      <a href={"/view-response?id=" + row.id}>{row.name}</a>
                    </>
                  </TableCell>
                  <TableCell align="right">
                    {
                      getTotalPoints(responses, fixtures, results[0], row.id)
                        .threep
                        
                    }
                    
                  </TableCell>
                  <TableCell align="right">
                    {
                      getTotalPoints(responses, fixtures, results[0], row.id)
                        .twop
                    }
                  </TableCell>
                  
                  <TableCell align="right">
                    {
                      getTotalPoints(responses, fixtures, results[0], row.id)
                      .zerop
                    }
                  </TableCell>
                  <TableCell align="right">
                    {
                      getTotalPoints(responses, fixtures, results[0], row.id)
                        .points
                    }
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Typography
        variant="h2"
        textAlign={"center"}
        fontSize={24}
        fontWeight={600}
        style={{ marginTop: "1rem" }}
        gutterBottom
        component="div"
        color={text}
      >
        Familj
      </Typography>
      <TableContainer className="standings-table-container" component={Paper}>
        <Table sx={{ minWidth: 150 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Familj</TableCell>
              <TableCell align="right">3p</TableCell>
              <TableCell align="right">2p</TableCell>
              <TableCell align="right">0p</TableCell>
              <TableCell align="right">Avg. Poäng</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.from(new Set(responses.map((item) => item.family)))
              .sort(
                (x, y) =>
                  getFamilyPoints(responses, fixtures, results[0], y).points -
                  getFamilyPoints(responses, fixtures, results[0], x).points
              )
              .map((row, index) => (
                <TableRow
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {index === 0 && (
                      <img className="crownIcon" src="crown.png"></img>
                    )}
                    {row}
                  </TableCell>
                  <TableCell align="right">
                    {
                      getFamilyPoints(responses, fixtures, results[0], row)
                        .threep
                    }
                  </TableCell>
                  <TableCell align="right">
                    {getFamilyPoints(responses, fixtures, results[0], row).twop}
                  </TableCell>
                  <TableCell align="right">
                    {
                      getFamilyPoints(responses, fixtures, results[0], row)
                        .zerop
                    }
                  </TableCell>
                  
                  <TableCell align="right">
                    {
                      getFamilyPoints(responses, fixtures, results[0], row)
                        .points
                    }
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default Standings;
