import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import FixtureSwingAnalyzer from "./pages/SendForm";
import { createTheme } from "@mui/material";
import { ThemeProvider } from "@emotion/react";
import Layout from "./components/Layout";
import { action, createStore, StoreProvider } from "easy-peasy";
import { StoreModel } from "./store/storeModel";
import SendForm from "./pages/SendForm";
import ViewResponse from "./pages/ViewResponse";
import Standings from "./pages/Standings";
import Admin from "./pages/Admin";
type Props = StoreProvider["props"] & { children: React.ReactNode };
const StoreProviderCasted =
  StoreProvider as unknown as React.ComponentType<Props>;

const store = createStore<StoreModel>({
  responses: [],
  addResponses: action((state, payload) => {
    state.responses = payload || 0;
  }),
  fixtures: [],
  results: [],
  addFixtures: action((state, payload) => {
    state.fixtures = payload || 0;
  }),
  addResults: action((state, payload) => {
    state.results = payload || 0;
  }),
  savedResponse: {
    name: "",
    prediction: { matches: [], nr1: "", nr2: "", nr3: "", scorer: "" },
    id: -1,
    family: "",
  },
  addSavedResponse:action((state, payload) => {
    state.savedResponse = payload || 0;
  })
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <BrowserRouter>
    <StoreProviderCasted store={store}>
      <Routes>
        <Route
          path="/"
          element={
            <Layout>
              <App />{" "}
            </Layout>
          }
        />
        <Route path="table" element={<Layout></Layout>} />
        <Route
          path="send-form"
          element={
            <Layout>
              <SendForm />
            </Layout>
          }
        />
        <Route
          path="admin"
          element={
            <Layout>
              <Admin />
            </Layout>
          }
        />
        <Route
          path="view-response"
          element={
            <Layout>
              <ViewResponse />
            </Layout>
          }
        />
        <Route
          path="standings"
          element={
            <Layout>
              <Standings />
            </Layout>
          }
        />
      </Routes>
    </StoreProviderCasted>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
