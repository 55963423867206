import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useStoreState } from "../components/Layout";
import { AdminResponses, Match, Responses } from "../store/storeModel";
import { getTeams } from "../utils/teamsHelper";
import FormRow from "../components/FormRow";
import ViewRow from "../components/ViewRow";
import { Password } from "@mui/icons-material";
import TextInput from "../components/TextInput";

type response = {
  status: string;
};

function Admin() {
  const [form, setForm] = useState<AdminResponses>({
    match: 0,
    homeGoals: 0,
    awayGoals: 0,
    password: "",
  });
  const [loading, setLoading] = useState<boolean>(false);
  const fixtures = useStoreState((state) => state.fixtures);
  const [submitResponse, setSubmitResponse] = useState<response | undefined>(
    undefined
  );

  const baseUrl =
    process.env.NEXT_STATIC_BASE_URL || "https://tipset-server.onrender.com";

  const text = "#cfcece";

  const handleChangePrediction = (
    index: number,
    goals: number,
    team: string,
    password: string
  ) => {
    if (team === "home") {
      let newform = form;
      newform.homeGoals = goals;
      newform.match = index;
      setForm(newform);
    } else if (team === "away") {
      let newform = form;
      newform.awayGoals = goals;
      newform.match = index;
      setForm(newform);
    }
  };

  const handlePasswordChange = (password: string) => {
    setForm({ ...form, password: password });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    console.log(form);
    setLoading(true);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        homeGoals: form.homeGoals,
        awayGoals: form.awayGoals,
        match: form.match + 1,
        password: form.password,
      }),
    };
    fetch(baseUrl + "/api/admin", requestOptions)
      .then((res) => res.json())
      .then((json: any) => {
        console.log(json);
        setSubmitResponse(json);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
    console.log(requestOptions);
  };
  return (
    <>
      <Typography
        variant="h1"
        textAlign={"center"}
        style={{ marginTop: "1rem" }}
        fontSize={54}
        gutterBottom
        component="div"
        color={text}
      >
        Tipset!
      </Typography>

      <div className="submit-form-container">
        <form className="submit-form" onSubmit={handleSubmit}>
          {!submitResponse ? (
            <>
              <div className="submit-form-matches">
                {fixtures.map((match: Match, index: number) => {
                  return match.homeGoals ? (
                    <ViewRow
                      key={index}
                      homeTeam={match.homeTeam}
                      awayTeam={match.awayTeam}
                      homeGoals={match.homeGoals ?? 0}
                      awayGoals={match.awayGoals ?? 0}
                      homeResult={
                        fixtures.find((x) => x.id === match.id)?.homeGoals
                      }
                      awayResult={
                        fixtures.find((x) => x.id === match.id)?.awayGoals
                      }
                      color={""}
                      index={index}
                    ></ViewRow>
                  ) : (
                    <FormRow
                      key={index}
                      homeTeam={match.homeTeam}
                      awayTeam={match.awayTeam}
                      color={text}
                      isRequired={false}
                      form={form.match === index ? form : undefined}
                      handleChange={handleChangePrediction}
                      index={index}
                    />
                  );
                })}
              </div>
              <TextInput
                name="password"
                label="Lösenord"
                type="password"
                handleChange={(name: string, value: string) =>
                  handlePasswordChange(value)
                }
                isRequired
              ></TextInput>
              <Button
                disabled={loading || !form.password}
                type="submit"
                variant="contained"
              >
                {!loading ? "Skicka in" : "Laddar..."}
                {loading && (
                  <CircularProgress
                    style={{ position: "absolute", right: "2rem" }}
                    size={24}
                    color="info"
                  />
                )}
              </Button>
            </>
          ) : (
            <>
              <Typography
                variant="h2"
                textAlign={"center"}
                fontSize={24}
                fontWeight={600}
                style={{ marginTop: "1rem" }}
                gutterBottom
                component="div"
                color={text}
              >
                Resultat inskickat!
              </Typography>
              <img
                style={{ height: "400px", objectFit: "contain" }}
                src="tack-min.jpg"
              ></img>
              <Typography
                variant="body1"
                textAlign={"center"}
                fontSize={16}
                fontWeight={600}
                style={{ marginTop: "1rem" }}
                gutterBottom
                component="div"
                color={text}
              >
                När turneringen börjar kan du följa ställningen{" "}
                <a href="/standings">här</a>!
              </Typography>
            </>
          )}
        </form>
      </div>
    </>
  );
}

export default Admin;
