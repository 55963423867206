import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useStoreActions, useStoreState } from "../components/Layout";
import { Match, Responses } from "../store/storeModel";
import { getTeams } from "../utils/teamsHelper";
import FormRow from "../components/FormRow";
import TextInput from "../components/TextInput";
import { Helmet } from "react-helmet";

type response = {
  status: string;
};

function SendForm() {
  const savedResponse = useStoreState((state) => state.savedResponse);
  console.log(savedResponse);
  const [form, setForm] = useState<Responses>(savedResponse ?? {
    name: "",
    prediction: { matches: [], nr1: "", nr2: "", nr3: "", scorer: "" },
    id: -1,
    family: "",
  });
  const addSavedResponse = useStoreActions((actions) => actions.addSavedResponse);
  const [loading, setLoading] = useState<boolean>(false);
  const [teams, setTeams] = useState<string[]>([]);
  const fixtures = useStoreState((state) => state.fixtures);
  const [submitResponse, setSubmitResponse] = useState<response | undefined>(
    undefined
  );

  const baseUrl =
    process.env.NEXT_STATIC_BASE_URL || "https://tipset-server.onrender.com";

  const text = "#cfcece";

  const handleChangePrediction = (
    index: number,
    goals: number,
    team: string
  ) => {
    if (team === "home") {
      console.log(index, goals);
      let newPrediction = { ...form.prediction };
      newPrediction.matches[index].homeGoals = goals;
      setForm({ ...form, prediction: newPrediction });
      addSavedResponse({ ...form, prediction: newPrediction })
    } else if (team === "away") {
      let newPrediction = { ...form.prediction };
      newPrediction.matches[index].awayGoals = goals;
      setForm({ ...form, prediction: newPrediction });
      addSavedResponse({ ...form, prediction: newPrediction })
    }
    console.log(form);
    
  };

  useEffect(() => {
    console.log("hej igen");
    setTeams(getTeams(fixtures));
    if (fixtures && !savedResponse.prediction.matches.length) {
      let initialForm: Responses = {
        name: "",
        prediction: { matches: [], nr1: "", nr2: "", nr3: "", scorer: "" },
        id: -1,
        family: "",
      };
      initialForm.prediction.matches = fixtures.map((fixture) => {
        let match: Match = {
          homeTeam: fixture.homeTeam,
          awayTeam: fixture.awayTeam,
          homeGoals: null,
          awayGoals: null,
          id: fixture.id,
        };
        return match;
      });
      setForm(initialForm);
    }
  }, [fixtures]);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    console.log(form);
    setLoading(true);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        name: form.name,
        prediction: form.prediction,
        family: form.family,
      }),
    };
    fetch(baseUrl + "/api/send-response", requestOptions)
      .then((res) => res.json())
      .then((json: any) => {
        console.log(json);
        setSubmitResponse(json);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const navigate = useNavigate();
  function handleTextChange(name: string, value: string) {
    if (name === "name") {
      setForm({ ...form, name: value });
    } else {
      let newPrediction = { ...form.prediction };
      if (name === "scorer") {
        newPrediction.scorer = value;
      }
      setForm({ ...form, prediction: newPrediction });
    }
  }

  return (
    <>
      <Helmet>
        <title>Skicka tips</title>
      </Helmet>
      <Typography
        variant="h1"
        textAlign={"center"}
        style={{ marginTop: "1rem" }}
        fontSize={54}
        gutterBottom
        component="div"
        color={text}
      >
        Tipset!
      </Typography>

      <div className="submit-form-container">
        <form className="submit-form" onSubmit={handleSubmit}>
          
          {!submitResponse ? (
            <>
            <div className="submit-form-help">
            <Typography
              variant="h2"
              fontSize={22}
              fontWeight={600}
              style={{ marginTop: "1rem" }}
              gutterBottom
              component="div"
              color={text}
            >
              Poängsystem:
            </Typography>
            <Typography
              variant="body1"
              fontSize={16}
              fontWeight={600}
              style={{ marginTop: "1rem" }}
              gutterBottom
              component="div"
              color={text}
            >
              Rätt resultat: 3p
            </Typography>
            <Typography
              variant="body1"
              fontSize={16}
              fontWeight={600}
              gutterBottom
              component="div"
              color={text}
            >
              Rätt vinnare(1X2): 2p
            </Typography>
            <Typography
              variant="body1"
              fontSize={16}
              fontWeight={600}
              gutterBottom
              component="div"
              color={text}
            >
              Fel: 0p
            </Typography>
            <Typography
              variant="body1"
              fontSize={16}
              fontWeight={600}
              marginTop={5}
              gutterBottom
              component="div"
              color={text}
            >
              Alla andra frågor ger 5 poäng var
            </Typography>
          </div>
              <div className="submit-form-matches">
                {fixtures.map((match: Match, index: number) => {
                  return (
                    <FormRow
                      key={index}
                      homeTeam={match.homeTeam}
                      awayTeam={match.awayTeam}
                      color={text}
                      isRequired
                      form={form.prediction.matches[index]}
                      handleChange={handleChangePrediction}
                      index={index}
                    />
                  );
                })}
              </div>
              <FormControl fullWidth color="primary">
                <InputLabel id="nr1">Guld</InputLabel>
                <Select
                  labelId="nr1"
                  id="nr1"
                  value={form.prediction.nr1}
                  onChange={(e) => {
                    let newPrediction = { ...form.prediction };
                    newPrediction.nr1 = e.target.value;
                    setForm({ ...form, prediction: newPrediction });
                  }}
                  label="nr1"
                  color="primary"
                  required
                >
                  {teams.map((team, index) => {
                    return (
                      <MenuItem key={index} value={team}>
                        {team}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <FormControl fullWidth color="primary">
                <InputLabel id="nr2">Silver</InputLabel>
                <Select
                  labelId="nr2"
                  id="nr2"
                  value={form.prediction.nr2}
                  onChange={(e) => {
                    let newPrediction = { ...form.prediction };
                    newPrediction.nr2 = e.target.value;
                    setForm({ ...form, prediction: newPrediction });
                  }}
                  label="nr2"
                  color="primary"
                  required
                >
                  {teams.map((team, index) => {
                    return (
                      <MenuItem key={index} value={team}>
                        {team}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <FormControl fullWidth color="primary">
                <InputLabel id="nr3">Brons</InputLabel>
                <Select
                  labelId="nr3"
                  id="nr3"
                  value={form.prediction.nr3}
                  onChange={(e) => {
                    let newPrediction = { ...form.prediction };
                    newPrediction.nr3 = e.target.value;
                    setForm({ ...form, prediction: newPrediction });
                  }}
                  label="nr3"
                  color="primary"
                  required
                >
                  {teams.map((team, index) => {
                    return (
                      <MenuItem key={index} value={team}>
                        {team}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <TextInput
                name="scorer"
                label="Bästa målskytt"
                handleChange={(name: string, value: string) =>
                  handleTextChange(name, value)
                }
                isRequired
              ></TextInput>
              <FormControl
                style={{ marginTop: "2rem" }}
                fullWidth
                color="primary"
              >
                <InputLabel id="family">Tillhör familj</InputLabel>
                <Select
                  labelId="family"
                  id="family"
                  value={form.family}
                  onChange={(e) => setForm({ ...form, family: e.target.value })}
                  label="family"
                  color="primary"
                  required
                >
                  <MenuItem value={"Josef & Hanna"}>Josef & Hanna</MenuItem>
                  <MenuItem value={"Johanna & Björn"}>Johanna & Björn</MenuItem>
                  <MenuItem value={"Jonas & Kerstin"}>Jonas & Kerstin</MenuItem>
                  <MenuItem value={"Joakim & Nico"}>Joakim & Nico</MenuItem>
                  <MenuItem value={"Jakob & Anne-Lie"}>
                    Jakob & Anne-Lie
                  </MenuItem>
                  <MenuItem value={"Jennie & Casey"}>Jennie & Casey</MenuItem>
                  <MenuItem value={"Joel & Emmie"}>Joel & Emmie</MenuItem>
                </Select>
              </FormControl>
              <TextInput
                name="name"
                label="Namn"
                handleChange={(name: string, value: string) =>
                  handleTextChange(name, value)
                }
                isRequired
              ></TextInput>
              <Button disabled={true} type="submit" variant="contained">
                {!loading ? "Skicka in" : "Laddar..."}
                {loading && (
                  <CircularProgress
                    style={{ position: "absolute", right: "2rem" }}
                    size={24}
                    color="info"
                  />
                )}
              </Button>
            </>
          ) : (
            <>
              <Typography
                variant="h2"
                textAlign={"center"}
                fontSize={24}
                fontWeight={600}
                style={{ marginTop: "1rem" }}
                gutterBottom
                component="div"
                color={text}
              >
                Tack för ditt tips, {form.name}!
              </Typography>
              <img
                style={{ height: "400px", objectFit: "contain" }}
                src="tack-min.jpg"
              ></img>
              <Typography
                variant="body1"
                textAlign={"center"}
                fontSize={16}
                fontWeight={600}
                style={{ marginTop: "1rem" }}
                gutterBottom
                component="div"
                color={text}
              >
                När turneringen börjar kan du följa ställningen{" "}
                <a href="/standings">här</a>!
              </Typography>
            </>
          )}
        </form>
      </div>
    </>
  );
}

export default SendForm;
