import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import NavBar from "./NavBar";
import { TextField, Typography } from "@mui/material";
import { AdminResponses, Match, Responses } from "../store/storeModel";
import { useDebounce } from "use-debounce";

type TextInputProps = {
  handleChange: any;
  isRequired: boolean;
  label: string;
  name: string;
  type?: string;
};
function TextInput({
  isRequired,
  handleChange,
  label,
  name,
  type,
}: TextInputProps) {
  const [answer, setAnswer] = useState<string>("");
  const [value] = useDebounce(answer, 500);

  useEffect(() => {
    handleChange(name, value);
  }, [value]);

  return (
    <TextField
      id={name}
      label={label}
      name={name}
      value={answer}
      onChange={(e) => {
        setAnswer(e.target.value);
      }}
      type={type ?? "text"}
      margin="normal"
      inputProps={{ style: { backgroundColor: "#3e485a" } }}
      fullWidth
      required={isRequired}
      style={{ marginTop: "2rem" }}
      InputLabelProps={{
        style: { color: "#fff" },
      }}
    />
  );
}

export default TextInput;
