import React from "react";
import { Link } from "react-router-dom";
import NavBar from "./NavBar";
import { TextField, Typography } from "@mui/material";
import { AdminResponses, Match, Responses } from "../store/storeModel";

type FormRowProps = {
  homeTeam: string;
  awayTeam: string;
  color: string;
  form?: Match | AdminResponses;
  index: number;
  handleChange: any;
  isRequired: boolean;
};
function FormRow({
  color,
  homeTeam,
  awayTeam,
  form,
  isRequired,
  index,
  handleChange,
}: FormRowProps) {
  console.log(index);
  return (
    <div>
      <div className="form-row">
        <Typography
          variant="body1"
          fontSize={18}
          fontWeight={600}
          component="span"
          color={color}
        >
          {homeTeam} - {awayTeam}
        </Typography>
        <div className="form-row-result">
          <TextField
            id={index + "home"}
            label="Hemma"
            name={index + "home"}
            type="number"
            value={form?.homeGoals}
            onChange={(e) => handleChange(index, e.target.value, "home")}
            required={isRequired}
            inputProps={{
              style: { backgroundColor: "#3e485a", padding: "0.5rem" },
            }}
            InputLabelProps={{ style: { color: "#fff", lineHeight: "13px" } }}
          />{" "}
          -
          <TextField
            id={index + "away"}
            label="Borta"
            name={index + "away"}
            value={form?.awayGoals}
            required={isRequired}
            type="number"
            onChange={(e) => handleChange(index, e.target.value, "away")}
            inputProps={{
              style: { backgroundColor: "#3e485a", padding: "0.5rem" },
            }}
            InputLabelProps={{
              style: { color: "#fff", lineHeight: "13px" },
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default FormRow;
