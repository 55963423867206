import React from 'react';
import { Link } from 'react-router-dom';
import NavBar from './NavBar';

type HeaderProps = {
    name?: string;
    setOpenDrawer?: any
    openDrawer?: boolean
    children?: React.ReactNode;
}

function Header({ name, setOpenDrawer, openDrawer }: HeaderProps) {
    return (
        <header className="Header">
            <nav
                style={{
                    borderBottom: "solid 1px",
                }}
            >
                <NavBar setOpenDrawer={setOpenDrawer} openDrawer={openDrawer}></NavBar>
            </nav>
        </header>
    );
}

export default Header;