import React from "react";
import { Link } from "react-router-dom";
import NavBar from "./NavBar";
import { TextField, Typography } from "@mui/material";
import { Match, Responses } from "../store/storeModel";
import { evaluateMatch } from "../utils/teamsHelper";

type FormRowProps = {
  homeTeam: string;
  awayTeam: string;
  homeGoals: number;
  awayGoals: number;
  color: string;
  index: number;
  showResult?: boolean;
  homeResult?: number | null;
  awayResult?: number | null;
};
function ViewRow({
  color,
  homeTeam,
  awayTeam,
  homeGoals,
  awayGoals,
  index,
  showResult,
  homeResult,
  awayResult,
}: FormRowProps) {
  console.log(homeResult);
  return (
    <div>
      <div className="view-row">
        <Typography
          variant="body1"
          fontSize={14}
          fontWeight={600}
          style={{ marginTop: "1rem" }}
          gutterBottom
          component="span"
          color={color}
        >
          {homeTeam} - {awayTeam}
        </Typography>
        <div className="view-row-result">
          <TextField
            id={index + "home"}
            label="Hemma"
            name={index + "home"}
            type="number"
            margin="normal"
            value={homeGoals}
            inputProps={{
              style: {
                backgroundColor: "#3e485a",
                width: "30px",
                padding: "10px",
                color: "white",
              },
            }}
            InputLabelProps={{
              style: { display: "none" },
            }}
            disabled
          />{" "}
          -
          <TextField
            id={index + "away"}
            label="Borta"
            name={index + "away"}
            type="number"
            margin="normal"
            value={awayGoals}
            disabled
            inputProps={{
              style: {
                backgroundColor: "#3e485a",
                width: "30px",
                padding: "10px",
              },
            }}
            InputLabelProps={{
              style: { display: "none" },
            }}
          />
        </div>

        {homeResult !== null &&
          homeResult !== undefined &&
          awayResult !== null &&
          awayResult !== undefined && (
            <div className="row-result-container">
              <span className="row-result-span">
                resultat: {homeResult} - {awayResult}
              </span>
              {showResult && (
                <span
                  className={
                    "result-" +
                    evaluateMatch(homeGoals, awayGoals, homeResult, awayResult)
                  }
                >
                  Poäng:{" "}
                  {evaluateMatch(homeGoals, awayGoals, homeResult, awayResult)}
                </span>
              )}
            </div>
          )}
      </div>
    </div>
  );
}

export default ViewRow;
