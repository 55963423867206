import { Match, Responses, Result } from "../store/storeModel";

export type PointsReponse = {
  zerop: number;
  twop: number;
  threep: number;
  points: number;
  threepgoals: number;
};

export const getTeams = (fixtures: Match[]): string[] => {
  let teams: string[] = [];
  fixtures.map((fixture: Match) => {
    if (!teams.includes(fixture.homeTeam)) {
      teams.push(fixture.homeTeam);
    }
  });
  return teams.sort((x, y) => (x > y ? 1 : -1));
};

export const getLeader = (responses: Responses[],
  fixtures: Match[],
  results: Result) => {
    responses.sort(
      (x, y) =>
        getTotalPoints(responses, fixtures, results, y.id).points -
        getTotalPoints(responses, fixtures, results, x.id).points || getTotalPoints(responses, fixtures, results, y.id).threep -
        getTotalPoints(responses, fixtures, results, x.id).threep || getTotalPoints(responses, fixtures, results, y.id).threepgoals -
        getTotalPoints(responses, fixtures, results, x.id).threepgoals || Math.abs(getTotalGoals(fixtures) - getTotalGoalsOfResponse(responses, x.id)) - Math.abs(getTotalGoals(fixtures) - getTotalGoalsOfResponse(responses, y.id))
    )
  return responses[0];
};

export const getTotalPoints = (
  responses: Responses[],
  fixtures: Match[],
  results: Result,
  id: number
): PointsReponse => {
  let response = responses.find((x) => x.id === id);
  let pointsObj: PointsReponse = {
    points: 0,
    zerop: 0,
    twop: 0,
    threep: 0,
    threepgoals: 0,
  };
  response?.prediction.matches.map((match, key: number) => {
    if (fixtures.find((x) => x.id === match.id)?.homeGoals !== null) {
      let matchPoints = evaluateMatch(
        match?.homeGoals ?? 0,
        match?.awayGoals ?? 0,
        fixtures.find((x) => x.id === match.id)?.homeGoals!,
        fixtures.find((x) => x.id === match.id)?.awayGoals!
      );
      if (matchPoints === 0) {
        pointsObj.zerop++;
      } else if (matchPoints === 2) {
        pointsObj.twop++;
      } else if (matchPoints === 3) {
        pointsObj.threep++;
        pointsObj.threepgoals = pointsObj.threepgoals + Number((match.homeGoals ?? 0)) + Number((match.awayGoals ?? 0));
      }
      pointsObj.points += matchPoints;
    }
  });
  if (response?.prediction.nr1 === results?.gold) {
    pointsObj.points += 10;
  }
  if (response?.prediction.nr2 === results?.silver) {
    pointsObj.points += 5;
  }
  if (response?.prediction.nr3 === results?.bronze || response?.prediction.nr3 === results?.bronze2) {
    pointsObj.points += 5;
  }
  if (response?.prediction.scorer === results?.scorer) {
    pointsObj.points += 5;
  }
  return pointsObj;
};

export const getFamilyPoints = (
  responses: Responses[],
  fixtures: Match[],
  results: Result,
  family: string
): PointsReponse => {
  let familyResponses = responses.filter((x) => x.family === family);
  let familyPointsObj: PointsReponse = {
    points: 0,
    zerop: 0,
    twop: 0,
    threep: 0,
    threepgoals: 0
  };
  familyResponses.map((response) => {
    familyPointsObj.zerop += getTotalPoints(
      responses,
      fixtures,
      results,
      response.id
    ).zerop;
    familyPointsObj.twop += getTotalPoints(
      responses,
      fixtures,
      results,
      response.id
    ).twop;
    familyPointsObj.threep += getTotalPoints(
      responses,
      fixtures,
      results,
      response.id
    ).threep;
    familyPointsObj.points += getTotalPoints(
      responses,
      fixtures,
      results,
      response.id
    ).points;
  });
  familyPointsObj.points =
    Math.round((familyPointsObj.points / familyResponses.length) * 100) / 100;
  return familyPointsObj;
};

export const evaluateMatch = (
  homeGoals: number,
  awayGoals: number,
  homeResult: number,
  awayResult: number
) => {
  if (homeResult === undefined || homeResult === null) {
    return 0;
  }
  if (homeGoals == homeResult && awayGoals == awayResult) {
    return 3;
  } else if (
    getSign(homeGoals, awayGoals) === getSign(homeResult, awayResult)
  ) {
    return 2;
  } else {
    return 0;
  }
};

export const evaluateNr1 = (results: Result, nr1: string) => {
  return results?.gold === nr1 ? 10 : 0;
};
export const evaluateNr2 = (results: Result, nr2: string) => {
  return results?.silver === nr2 ? 5 : 0;
};
export const evaluateNr3 = (results: Result, nr3: string) => {
  return results?.bronze === nr3 || results?.bronze2 === nr3 ? 5 : 0;
};
export const evaluateScorer = (results: Result, scorer: string) => {
  return results?.scorer === scorer ? 5 : 0;
};

const getSign = (homeGoals: number, awayGoals: number) => {
  if (homeGoals > awayGoals) {
    return "1";
  } else if (homeGoals === awayGoals) {
    return "X";
  } else if (awayGoals > homeGoals) {
    return "2";
  } else return "error";
};

export const getTotalGoals = (fixtures: Match[],) => {
  let sum = fixtures.reduce((accumulator, current) => accumulator + Number(current.awayGoals ?? 0) + Number(current.homeGoals ?? 0), 0);

  return sum;
};
export const getTotalGoalsOfResponse = (responses: Responses[], id: Number) => {
  let response = responses.find((x) => x.id === id);
  let sum = response?.prediction.matches.reduce((accumulator, current) => accumulator + Number(current.awayGoals ?? 0) + Number(current.homeGoals ?? 0), 0);

  return sum ?? 0;
};
