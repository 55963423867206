import React from "react";
import logo from "./logo.svg";
import "./App.css";
import { Link, useNavigate } from "react-router-dom";
import Header from "./components/Header";
import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  Typography,
} from "@mui/material";
import { Helmet } from "react-helmet";
import Hero from "./components/Hero";
import { useStoreState } from "./components/Layout";
import Countdown from "react-countdown";

function App() {
  const navigate = useNavigate();
    return (
    <>
      <Helmet>
        <title>Tipset</title>
        <meta property="og:title" content="Melintipset" />
        <meta property="og:description" content="Vem vinner den åtråvärda bildliga Melinpokalen?" />
        <meta property="og:image" content="https://melintipset.xyz/hero3.jpg" />
      </Helmet>
      <Hero></Hero>
      <Typography
        variant="h2"
        textAlign={"center"}
        style={{ paddingTop: "2rem" }}
        gutterBottom
        component="div"
      >
        Melin-tipset
      </Typography>
      <div className="startpageCards">
        {/*<Button disabled  onClick={() => navigate("/send-form")} variant="contained">
          Skicka in tips
        </Button>*/}
        <Button onClick={() => navigate("/standings")} variant="contained">
          Tabell
        </Button>
      </div>
       {/* <div className="countdown">
        Tid kvar till deadline:{" "}
        <Countdown date={new Date(2024, 5, 14, 21, 0, 0)} />
      </div>*/}
    </>
  );
}

export default App;
