import { createTheme, Paper, ThemeProvider, Typography } from "@mui/material";
import { action, createStore, StoreProvider } from "easy-peasy";
import React, { useEffect } from "react";
import "../styles/Table.scss";
import "../styles/Header.scss";
import "../styles/Layout.scss";
import "../styles/sendForm.scss";
import "../styles/viewResponse.scss";
import "../styles/FixtureSwing.scss";
import Header from "./Header";
import { StoreModel } from "../store/storeModel";
import { createTypedHooks } from "easy-peasy";
import DrawerComponent from "./Drawer";

type TableProps = {
  children?: React.ReactNode;
};

declare module "@mui/material/styles" {
  interface Theme {
    status: {
      danger: string;
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    status?: {
      danger?: string;
    };
  }
}

const typedHooks = createTypedHooks<StoreModel>();

export const useStoreActions = typedHooks.useStoreActions;
export const useStoreDispatch = typedHooks.useStoreDispatch;
export const useStoreState = typedHooks.useStoreState;
const baseUrl =
  process.env.NEXT_STATIC_BASE_URL || "https://tipset-server.onrender.com";

const theme = createTheme({
  palette: {
    primary: {
      main: "#38003c",
    },
    secondary: {
      main: "#04f5ff",
    },
    text: {
      primary: "#cfcece",
    },
    action: {
      active: "#fff",
    },
    background: {
      default: "#162020",
      paper: "#ffffff",
    },
  },
});

function Layout({ children }: TableProps) {
  const addResponses = useStoreActions((actions) => actions.addResponses);
  const fixtures = useStoreState((state) => state.fixtures);
  const responses = useStoreState((state) => state.responses);
  const addFixtures = useStoreActions((actions) => actions.addFixtures);
  const addResults = useStoreActions((actions) => actions.addResults);
  const [openDrawer, setOpenDrawer] = React.useState(false);

  const fetchFixtures = async () => {
    if (!fixtures?.length) {
      fetch(baseUrl + "/api/get-fixtures")
        .then((res) => res.json())
        .then((json: any) => {
          addFixtures(json);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const fetchResults = async () => {
    if (!fixtures?.length) {
      fetch(baseUrl + "/api/get-results")
        .then((res) => res.json())
        .then((json: any) => {
          addResults(json);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const fetchResponses = async () => {
    if (!responses?.length) {
      fetch(baseUrl + "/api/get-responses")
        .then((res) => res.json())
        .then((json: any) => {
          addResponses(json);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    fetchFixtures();
    fetchResponses();
    fetchResults();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <div>
        <Header setOpenDrawer={setOpenDrawer} openDrawer={openDrawer} />

        <main className="container">
          {" "}
          {children}
          {openDrawer && (
            <DrawerComponent setOpenDrawer={setOpenDrawer}></DrawerComponent>
          )}
        </main>
      </div>
    </ThemeProvider>
  );
}

export default Layout;
