import { Drawer, List, ListItem, ListItemText } from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-router-dom";

function DrawerComponent({ setOpenDrawer }: any) {
  return (
    <Drawer variant="permanent" className="drawer">
      <List>
        <ListItem>
          <ListItemText>
            <Link to="/" onClick={() => setOpenDrawer(false)}>
              Home
            </Link>
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>
            {/*<Link to="/send-form" onClick={() => setOpenDrawer(false)}>
              Skicka in tips
            </Link>*/}
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>
            <Link to="/standings" onClick={() => setOpenDrawer(false)}>
              Tabell
            </Link>
          </ListItemText>
        </ListItem>
      </List>
    </Drawer>
  );
}
export default DrawerComponent;
