import { createTheme, Paper, ThemeProvider, Typography } from "@mui/material";
import { action, createStore, StoreProvider } from "easy-peasy";
import React, { useEffect } from "react";
import "../styles/Layout.scss";
import { useStoreState } from "./Layout";
import PrizesLeftBlock from "./PrizesLeftBlock";

type TableProps = {
  children?: React.ReactNode;
};

declare module "@mui/material/styles" {
  interface Theme {
    status: {
      danger: string;
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    status?: {
      danger?: string;
    };
  }
}

function Hero() {
  return (
    <div className="hero-container">
      <img src="hero3.jpg"></img>
      {<PrizesLeftBlock></PrizesLeftBlock>}
      <div className="hero-container-gradient"></div>
    </div>
  );
}

export default Hero;
