import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Button, CircularProgress, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useStoreState } from "../components/Layout";
import { Match, Responses } from "../store/storeModel";
import ViewRow from "../components/ViewRow";
import {
  evaluateNr1,
  evaluateNr2,
  evaluateNr3,
  evaluateScorer,
  getTotalPoints,
} from "../utils/teamsHelper";

function ViewResponse() {
  const [form, setForm] = useState<Responses>({
    name: "",
    prediction: { matches: [], nr1: "", nr2: "", nr3: "", scorer: "" },
    id: -1,
    family: "",
  });
  const [loading, setLoading] = useState<boolean>(false);
  const fixtures = useStoreState((state) => state.fixtures);
  const results = useStoreState((state) => state.results);
  const responses = useStoreState((state) => state.responses);
  const [response, setResponse] = useState<Responses | undefined>();
  const [searchParams, setSearchParams] = useSearchParams();
  const [totalPoints, setTotalPoints] = useState<number | undefined>();
  const [index, setIndex] = useState<number>(-1);

  const baseUrl =
    process.env.NEXT_STATIC_BASE_URL || "https://tipset-server.onrender.com";

  useEffect(() => {
    if (responses.length) {
      responses.map((response: Responses) => {
        console.log(typeof response.prediction.matches);
      });
    }

    setResponse(responses.find((x) => x.id === index));
  }, [responses]);

  useEffect(() => {
    if (responses && fixtures && results) {
      setTotalPoints(
        getTotalPoints(responses, fixtures, results[0], index).points
      );
    }
  }, [responses, fixtures, results]);

  useEffect(() => {
    if (searchParams.get("id")) {
      setIndex(Number.parseInt(searchParams.get("id")!) || 0);
    }
  }, [searchParams]);

  const text = "#cfcece";
  const navigate = useNavigate();
  return (
    <>
      {response ? (
        <>
          <Typography
            variant="h1"
            textAlign={"center"}
            style={{ marginTop: "1rem" }}
            fontSize={54}
            gutterBottom
            component="div"
            color={text}
          >
            Tipset!
          </Typography>

          <div className="submit-form-container">
            <div className="viewResponseContainer">
              <h2>{response.name}</h2>
              {response!.prediction.matches.map((match: Match, key: number) => {
                return (
                  <ViewRow
                    key={key}
                    homeTeam={match.homeTeam}
                    awayTeam={match.awayTeam}
                    homeGoals={match.homeGoals ?? 0}
                    awayGoals={match.awayGoals ?? 0}
                    showResult
                    homeResult={
                      fixtures.find((x) => x.id === match.id)?.homeGoals
                    }
                    awayResult={
                      fixtures.find((x) => x.id === match.id)?.awayGoals
                    }
                    color={""}
                    index={key}
                  ></ViewRow>
                );
              })}
              <Typography
                variant="h2"
                textAlign={"center"}
                fontSize={18}
                fontWeight={600}
                style={{ marginTop: "1rem" }}
                gutterBottom
                component="div"
                color={text}
                className="results-row"
              >
                <span>Guld: {response.prediction.nr1} </span>

                {results[0]?.gold && (
                  <span>
                    Poäng: {evaluateNr1(results[0], response.prediction.nr1)}
                  </span>
                )}
              </Typography>
              <Typography
                variant="h2"
                textAlign={"center"}
                fontSize={18}
                fontWeight={600}
                style={{ marginTop: "1rem" }}
                gutterBottom
                component="div"
                color={text}
                className="results-row"
              >
                <span>Silver: {response.prediction.nr2} </span>
                {results[0]?.silver && (
                  <span>
                    Poäng: {evaluateNr2(results[0], response.prediction.nr2)}
                  </span>
                )}
              </Typography>
              <Typography
                className="results-row"
                variant="h2"
                textAlign={"center"}
                fontSize={18}
                fontWeight={600}
                style={{ marginTop: "1rem" }}
                gutterBottom
                component="div"
                color={text}
              >
                <span>Brons: {response.prediction.nr3} </span>

                {results[0]?.bronze && (
                  <span>
                    Poäng: {evaluateNr3(results[0], response.prediction.nr3)}
                  </span>
                )}
              </Typography>
              <Typography
                className="results-row"
                variant="h2"
                textAlign={"center"}
                fontSize={18}
                fontWeight={600}
                style={{ marginTop: "1rem" }}
                gutterBottom
                component="div"
                color={text}
              >
                <span>Bästa målgörare: {response.prediction.scorer} </span>
                {results[0]?.scorer && (
                  <span>
                    Poäng:{" "}
                    {evaluateScorer(results[0], response.prediction.scorer)}
                  </span>
                )}
              </Typography>
              <Typography
                variant="h2"
                textAlign={"center"}
                fontSize={24}
                fontWeight={600}
                style={{ marginTop: "1rem" }}
                gutterBottom
                component="div"
                color={text}
              >
                Poäng: {totalPoints}
              </Typography>
            </div>
          </div>
        </>
      ) : (
        <div></div>
      )}
    </>
  );
}

export default ViewResponse;
